<template>
    <section
        v-if="config.calendar && config.calendar.enabled && events !== undefined"
        class="alert alert-light border"
    >
        <div class="d-flex flex-column-reverse flex-md-row justify-content-between">
            <p class="h3">
                <Icon v="calendar-star" />
                <T>calendar.banner</T><T>quotation.colon</T>
            </p>
            <p class="small">
                <DateWords :day="day" inline />
            </p>
        </div>
        <ul class="list-unstyled my-3 ms-3">
            <li v-for="event in events" class="mb-2">
                <CalendarEvent :key="event.name" :event="event" />
            </li>
        </ul>
        <nuxt-link v-if="link" :to="{ name: 'calendar' }" class="small">
            <Icon v="angle-right" />
            <T>calendar.headerLong</T>
        </nuxt-link>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import { buildCalendar } from '../src/calendar/calendar.ts';
import { Day } from '../src/calendar/helpers.ts';

export default defineComponent({
    props: {
        day: { default: () => Day.today(), type: Day },
        link: { type: Boolean },
    },
    setup(props) {
        const runtimeConfig = useRuntimeConfig();

        return {
            config: useConfig(),
            events: buildCalendar(runtimeConfig.public.baseUrl).getCurrentYear()!.eventsByDate[props.day.toString()],
        };
    },
});
</script>
